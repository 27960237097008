import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
  const { t } = useTranslation("global");

  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    consent: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [responseMessage, setResponseMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://www.enotorrerecords.com/form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (result.status === 'success') {
        setResponseMessage(t('form.onSuccess'));
        setMessageType('success');
        setFormData(initialFormData);
      } else {
        setResponseMessage(`Error: ${result.error.join(', ')}`);
        setMessageType('error');
      }
    } catch (error) {
      setResponseMessage(t('form.onFail'));
      setMessageType('error');
    }
  };

  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => {
        setResponseMessage('');
        setMessageType('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [responseMessage]);
  

  return (
      <div className="p-0 mt-2 lg:mt-20 bg-90% bg-left bg-no-repeat gap-2 flex flex-col lg:flex-row mb-16">
        <div className='w-full lg:w-1/3 flex flex-col justify-between'>
        
        <h2 className="text-5xl text-enoOrange-200 font-bold  mb-2 md:mb-8">{t('form.contact')}</h2>
        
        <div>
        <a className='text-white flex flex-row hover:text-enoOrange-200 transition-colors duration-700 font-serif 
        text-base lg:text-xl items-center gap-3' href="mailto:info@enotorrerecords.com">
          <FontAwesomeIcon icon={faEnvelope}
            className="text-2xl  sm:text-3xl align-top xl:text-4xl"
          />info@enotorrerecords.com</a>
          <a className='text-white hover:text-green-600 transition-colors duration-700 flex flex-row  font-serif text-base lg:text-xl items-center gap-3' target='_blank' href="https://wa.me/393886064901?text=I'm%20interested%20in%20purchasing%20the%20CD%20from%20Enotorrerecords.com">
          <FontAwesomeIcon icon={faWhatsapp}
            className="text-2xl  sm:text-3xl align-top xl:text-4xl"
          />+39 388 606 4901</a>
      </div>
        </div>
        <div className='w-full lg:w-2/3'>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-6">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder={t('form.first')}
                className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-enoOrange-200 rounded-md focus:outline-none text-white bg-[#303030] focus:ring-2 focus:ring-enoOrange-200"
                required
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder={t('form.last')}
                className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-enoOrange-200  rounded-md focus:outline-none text-white bg-[#303030] focus:ring-2 focus:ring-enoOrange-200"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-2 lg:mt-4">
            <input
              type="email"
              name="email"
              placeholder="E-mail*"
              className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-enoOrange-200  rounded-md focus:outline-none text-white bg-[#303030] focus:ring-2 focus:ring-enoOrange-200"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mt-2 lg:mt-4">
            <input
              type="text"
              name="subject"
              placeholder={t('form.subject')}
              className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-enoOrange-200  rounded-md focus:outline-none text-white bg-[#303030] focus:ring-2 focus:ring-enoOrange-200"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
          <div className="mt-2 lg:mt-4">
            <textarea
              name="message"
              placeholder={t('form.message')}
              rows="4"
              className="w-full px-4 py-2 transition-border duration-150 ease-linear border-l-2 focus:border-l-4 border-l-enoOrange-200  rounded-md focus:outline-none text-white bg-[#303030] focus:ring-2 focus:ring-enoOrange-200"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
         

            
            <label className="container" htmlFor="consent" >  {t('form.autorize')} <a href="/privacy" className="text-enoOrange-200">Privacy Policy</a>*
              <input type="checkbox" name="consent" id="consent" required checked={formData.consent} onChange={handleChange}
                  ></input>
              <span className="checkmark"></span>
            </label>


          <div className="mt-8">
          <button
            type="submit"
            className=" border-enoOrange-200 text-white p-2 text-base px-6 border-2 mt-0 md:mt-4 hover:border-enoOrange-200
             hover:rounded-lg hover:bg-enoOrange-200 hover:-rotate-1 uppercase leading-[1] transition-rotate 
             duration-300 ease-in-out text-center w-80 "
          >
            {t('form.send')}
          </button>
        </div>
      </form>
      {responseMessage && (
        <div className={`mt-4 p-4 rounded-md ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {responseMessage}
        </div>
      )}
      </div>
    </div>
  );
};

export default ContactForm;
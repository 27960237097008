import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function Dicono() {
    const { t } = useTranslation("global");
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const magSize = 400; // Size of the magnifier
    const zoomLevel = 2; // Zoom level

    const imgRef = useRef(null);

    const handleMouseMove = (e) => {
        const elem = imgRef.current;
        const { top, left } = elem.getBoundingClientRect();
        const x = e.pageX - left - window.scrollX;
        const y = e.pageY - top - window.scrollY;
        setXY([x, y]);
    };

    const handleMouseEnter = () => {
        const elem = imgRef.current;
        const { width, height } = elem.getBoundingClientRect();
        setSize([width, height]);
        setShowMagnifier(true);
    };

    const handleMouseLeave = () => {
        setShowMagnifier(false);
    };

    return (
        <div className="h-auto bg-enoDark overflow-hidden rounded-none md:rounded-lg relative snap-start flex flex-col md:flex-row items-center justify-center p-4 sm:p-4 md:p-16 lg:p-24 xl:p-32 gap-8">
            <div className="relative w-full md:w-full">
                <img 
                    ref={imgRef}
                    src='/img/articolo-enotorrerecords-rivista-suono.webp' 
                    alt='articolo enotorrerecords rivista suono' 
                    className='w-full'
                    onMouseEnter={handleMouseEnter}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                />
                {showMagnifier && (
                    <div
                        style={{
                            position: "absolute",
                            border: "10px solid #fff",
                            boxShadow: "0px 5px 10px #808080",
                            borderRadius: "50%",
                            pointerEvents: "none",
                            opacity: 1,
                            backgroundColor: "white",
                            backgroundImage: `url('/img/articolo-enotorrerecords-rivista-suono.webp')`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
                            backgroundPositionX: `${-x * zoomLevel + magSize / 2}px`,
                            backgroundPositionY: `${-y * zoomLevel + magSize / 2}px`,
                            left: `${x - magSize / 2}px`,
                            top: `${y - magSize / 2}px`,
                            width: `${magSize}px`,
                            height: `${magSize}px`,
                        }}
                    />
                )}
            </div>
            <div className="flex flex-col justify-center items-start gap-2">
                <h1 className="font-sans text-white text-xl sm:text-2xl md:text-5xl mb-2">
                    <span className="">{t('press.title')}</span>
                </h1>
                <p className="mb-4 font-serif text-white text-sm sm:text-base sm:leading-[1.25]">
                    {t('press.p3')}
                </p>
                <div className="max-w-3xl flex flex-col">
                    <a
                        href={t('press.linkPressUrl')}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-white pb-2 text-left hover:underline text-enoOrange-200 transition-colors text-xl duration-700 transitio-border-color ease-in-out w-full sm:w-auto"
                    >
                        {t('press.linkPress')}
                    </a>
                    <a
                        href={t('press.linkReviewUrl')}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-white pb-2 text-left hover:underline text-enoOrange-200 transition-colors text-xl duration-700 transitio-border-color ease-in-out w-full sm:w-auto"
                    >
                        {t('press.linkReview')}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Dicono;
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faMusic, faUsers, faMicrophone, faBookOpen, faTimes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '../components/Form';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { NavLink } from '../App';
import { useInView } from 'react-intersection-observer';
import Experience from '../Experience';
import Footer from '../components/Footer';

const AlbumPage = () => {
  const { t } = useTranslation("global");
  const galleryRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [popupStyle, setPopupStyle] = useState({});
  const [isAnimating, setIsAnimating] = useState(false);
  const [initialImageRect, setInitialImageRect] = useState(null);

  

  const tracks = [
    { title: "ODE TO ELVIN", composer: "Danilo Memoli" },
    { title: "NOBODY ELSE BUT ME", composer: "Jerome Kern" },
    { title: "WALTZ FOR RUTH", composer: "Charlie Haden" },
    { title: "VUELTA CARNERO", composer: "David Boato" },
    { title: "VISITOR", composer: "Dan Kinzelman" },
    { title: "OCLUPACA", composer: "Duke Ellington" },
    { title: "WEBB CITY", composer: "Bud Powell" },
  ];

  const bandMembers = [
    { name: "MASSIMO CHIARELLA", instrument: t('album.member1') },
    { name: "DANILO MEMOLI", instrument: t('album.member2') },
    { name: "DAVID BOATO", instrument: t('album.member3') },
    { name: "RICCARDO DI VINCI", instrument: t('album.member4') },
  ];

  const images = [
    '/photos/2.webp', '/photos/5.webp', '/photos/8.webp', '/photos/12.webp',
    '/photos/9.webp', '/photos/6.webp', '/photos/1.webp', '/photos/3.webp',
    '/photos/11.webp', '/photos/10.webp', '/photos/4.webp', '/photos/13.webp'
  ];

  const handleImageClick = (image, event) => {
    const rect = event.target.getBoundingClientRect();
    setInitialImageRect(rect);
    const initialStyle = {
      position: 'fixed',
      top: `${rect.top}px`,
      left: `${rect.left}px`,
      width: `${rect.width}px`,
      height: `${rect.height}px`,
      transition: 'all 0.3s ease-in-out',
      zIndex: 1000,
    };

    setSelectedImage(image);
    setPopupStyle(initialStyle);
    setIsAnimating(true);

    // Trigger the animation to full screen
    setTimeout(() => {
      setPopupStyle({
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        transition: 'all 0.3s ease-in-out',
        zIndex: 1000,
      });
    }, 50);

    // Set animation as complete
    setTimeout(() => {
      setIsAnimating(false);
    }, 350);
  };

  const closePopup = () => {
    if (isAnimating) return;

    setIsAnimating(true);

    // Animate back to full screen size first
    setPopupStyle({
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      transition: 'all 0.3s ease-in-out',
      zIndex: 1000,
    });

    // Then animate back to original position
    setTimeout(() => {
      setPopupStyle({
        position: 'fixed',
        top: `${initialImageRect.top}px`,
        left: `${initialImageRect.left}px`,
        width: `${initialImageRect.width}px`,
        height: `${initialImageRect.height}px`,
        transition: 'all 0.3s ease-in-out',
        zIndex: 1000,
      });
    }, 50);

    // Reset after animation completes
    setTimeout(() => {
      setSelectedImage(null);
      setPopupStyle({});
      setIsAnimating(false);
      setInitialImageRect(null);
    }, 350);
  };

  const contactContainerRef = useRef(null);


  const handleScroll = (e) => {
    e.preventDefault(); // Prevent default link behavior
    const contactContainer = contactContainerRef.current;
    if (contactContainer) {
      contactContainer.scrollIntoView({ behavior: 'smooth' });
    }
  };



  return <>
   
      

      {/* Content */}
      <div className="relative z-10 overflow-hidden bg-enoDark text-white">
        
        {/* Album Header */}
        <section className="content-center ">
          <div className="flex flex-col  xl:flex-row mt-5 ">
            <div className="h-[60vh] xl:h-[70vh] w-full xl:w-3/5">
            
          <Suspense fallback={( <div className="flex justify-center bg-enoDark items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-zucsGreen-200"></div>
        </div>)}>
              <Experience  />
            </Suspense>
            </div>
            <div className="w-full xl:w-2/5 flex flex-col justify-center p-2">
              <h1 className="text-enoOrange-200 uppercase text-2xl sm:text-2xl md:text-5xl mb-2">
                MASSIMO CHIARELLA QUARTET CD
              </h1>
              <p className="font-serif text-white w-full leading-tight lg:w-4/5 text-sm mt-2">
                {t('album.shortDescription')}
              </p>
              <a className="text-enoOrange-200 bg-white p-2 text-base px-6 border-2 mt-4 
               hover:rounded-lg hover:bg-enoOrange-200 hover:text-white hover:-rotate-1 uppercase leading-[1] transition-rotate 
               duration-300 ease-in-out text-center w-52 lg:w-80" 
               onClick={handleScroll}
                 href="#">
                <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
                {t('album.buyNow')}
              </a>
            </div>
          </div>
         <div className='mt-5 px-2 xl:px-20'>
         <h2 className="text-enoOrange-200 uppercase text-xl sm:text-2xl md:text-3xl mb-4">
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              {t('album.bandMembers')}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-1 md:gap-4">
              {bandMembers.map((member, index) => (
                <div key={index} className="bg-white/5 p-1 md:p-4 rounded">
                  <h3 className="text-enoOrange-200">{member.name}</h3>
                  <p>{member.instrument}</p>
                </div>
              ))}
            </div>
         </div>
        </section>

    


        {/* Album details*/}
        <section className="px-4 sm:px-4 flex flex-col md:flex-row mt-40  gap-10 md:px-12 lg:px-16 xl:px-24">
          <div className='w-full md:w-2/5'>
           
            <h2 className="text-enoOrange-200 uppercase text-xl sm:text-2xl md:text-3xl mb-4">
              <FontAwesomeIcon icon={faMusic} className="mr-2" />
              {t('album.trackList')}
            </h2>
        
            <div>
              {tracks.map((track, index) => (
                <div key={index} className="flex justify-between items-center border-b border-white/20 py-2">
                  <span>{track.title}</span>
                  <span className="text-enoOrange-200">{track.composer}</span>
                </div>
              ))}
            </div>
          </div>
          <div className='w-full md:w-3/5'>
            <h2 className="text-enoOrange-200 uppercase text-xl sm:text-2xl md:text-3xl mb-4">
              <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
              {t('album.hDescription')}
            </h2>
            <p className="mb-4 font-serif ont-serif text-white text-sm sm:text-base sm:leading-[1.25]">
            {t('album.pDescription1')}<br /> <br />
            {t('album.pDescription2')}
            </p>
          </div>
        </section>


          


       {/* iframe + microphones */}
       <div className="m-0  h-[calc(100dvh)]  rounded-none md:rounded-lg snap-center
    flex flex-col md:flex-row items-center gap-6 justify-center p-0 ">
    
    
    <img className="md:h-auto w-full xl:w-2/5 rounded-lg shadow-lg 'p-4 object-cover" src="/img/fullbackcover800px.webp" alt="Album Cover" />
     

      <div className="w-full xl:w-2/5 p-4 flex flex-col gap-2 items-start justify-center">
      
      <h3 className="text-enoOrange-200 font-sans uppercase text-xl sm:text-2xl md:text-3xl mb-4">
          <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
          {t('album.microphonesUsed')}</h3>
            <ul className="list-disc list-outside font-serif ml-5 text-white text-sm sm:text-base sm:leading-[1.25]">
            
              <li>
              <strong>{t('album.fiati')}</strong>: AKG The Tube con valvola selezionata RCA6072
              </li>
              <li>
              <strong>{t('album.pianoforte')}</strong>: Schoeps CMC6 capsula MK4
              </li>
              <li>
              <strong>{t('album.batteria')}</strong>:
              <ul className='list-disc list-inside ml-2 font-serif text-white text-sm sm:text-base sm:leading-[1.25]'>
                <li>
                panoramico: Schoeps CMC6 capsula MK21
                </li>
                <li>
                rullante sopra: AKG C451
                </li>
                <li>
                rullante sotto: Shure SM98D
                </li>
                <li>
                cassa: CAD E100 MK4
                </li>
                <li>
                tom e timpano: Audio Tecnica AE3000
                </li>
                <li>
                charleston: Schoeps CMC6 MK4
                </li>
              </ul>
              </li>
              <li>
              <strong>{t('album.contrabbasso')}</strong>:</li> 
                <ul className='list-disc list-inside ml-2 font-serif text-white text-sm sm:text-base sm:leading-[1.25]'>
                  <li>davanti: Neumann U87 </li>
                  <li>ponte: Schoeps CMC6 capsula MK2
              </li>
              </ul>
              <li>
              <strong>{t('album.panoramico')}</strong>: Neumann USM69 (X-Y conf) + Shoeps MTSC64 (ORTF conf)
              </li>
              </ul>
        
      </div>

      </div>

  {/* Bento Grid Gallery */}
  <section ref={galleryRef} className="mt-52 p-4">
          <div className="py-4 mx-auto h-[50vh] md:h-[70vh] sm:py-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">
              <div className="col-span-2 sm:col-span-1 md:col-span-2 h-full md:h-full flex flex-col">
                <div className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow cursor-pointer" onClick={(e) => handleImageClick(images[8], e)}>
                  <img src={images[8]} alt="band member from Massimo Chiarella Quartet" className={`${selectedImage === images[8] ? 'opacity-0' : ''} absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out`} />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                </div>
              </div>
              <div className="grid col-span-2 gap-4 sm:col-span-1 md:col-span-2 h-full ">
                <div className="group relative h-full flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 cursor-pointer" onClick={(e) => handleImageClick(images[3], e)}>
                  <img src={images[3]} alt="band member from Massimo Chiarella Quartet" className={`${selectedImage === images[3] ? 'opacity-0' : ''} absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out`} />
                  <div className="absolute  inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                </div>
                <div className="grid gap-4 grid-cols-2 h-full sm:grid-cols-2 lg:grid-cols-2">
                  <div className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 cursor-pointer" onClick={(e) => handleImageClick(images[4], e)}>
                    <img src={images[4]} alt="band member from Massimo Chiarella Quartet" className={`${selectedImage === images[4] ? 'opacity-0' : ''} absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out`} />
                    <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  </div>
                  <div className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 cursor-pointer" onClick={(e) => handleImageClick(images[11], e)}>
                    <img src={images[11]} alt="band member from Massimo Chiarella Quartet" className={`${selectedImage === images[11] ? 'opacity-0' : ''} absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out`} />
                    <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  </div>
                </div>
              </div>
              <div className="col-span-2 hidden sm:col-span-1 md:col-span-1 h-auto md:h-full md:flex flex-col">
                <div className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow cursor-pointer" onClick={(e) => handleImageClick(images[9], e)}>
                  <img src={images[9]} alt="band member from Massimo Chiarella Quartet" className={`${selectedImage === images[9] ? 'opacity-0' : ''} absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out`} />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
  {/* Image Popup */}
  {selectedImage && (
        <>
          <div 
            className="fixed inset-0 z-40 bg-black transition-opacity duration-300 ease-in-out"
            style={{ opacity: isAnimating ? 0 : 0.75 }}
            onClick={closePopup}
          ></div>
          <div style={popupStyle} className="flex items-center justify-center overflow-hidden">
            <img
              src={selectedImage}
              alt="Full size band member from Massimo Chiarella Quartet"
              className="popup-image max-w-full max-h-full object-contain"
            />
            {!isAnimating && (
              <button
                onClick={closePopup}
                className="absolute bottom-20 lg:bottom-10 text-white text-2xl hover:text-gray-300 transition-colors duration-200"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        </>
      )}

        {/* Project Presentation */}
        <section className="p-4 sm:p-4 mt-52 flex flex-col md:flex-row gap-10 md:p-12 lg:p-16 xl:px-24">
          <div className='w-full lg:w-1/3 xl:w-1/2'>
          <img src="/img/jazzseries.webp" alt="Footer Logo jazz series" className="md:mr-8" />
          </div>
          <div className='w-full lg:w-2/3 xl:w-1/2  font-serif'>
            <h2 className="text-enoOrange-200 font-sans uppercase text-xl sm:text-2xl md:text-3xl mb-4">
              <FontAwesomeIcon icon={faBookOpen} className="mr-2" />
              {t('album.projectPresentation')}
            </h2>
            <p className="mb-4 font-serif text-white text-sm sm:text-base sm:leading-[1.25]" >
              {t('album.pProgetto1')}
            </p>
            <p className="mb-4 font-serif text-white text-sm sm:text-base sm:leading-[1.25]">
            {t('album.pProgetto2')}
            
            </p>
            <p className='font-serif text-white text-sm sm:text-base sm:leading-[1.25]'>
            {t('album.pProgetto3')}
            </p>
            <NavLink to="/about" className='w-[100%] mt-4 text-enoOrange-200 text-right'> Antonio Sarcinelli Postiglione, Roberto Rocchi</NavLink>
          </div>
        </section>


        {/* CTA disco HD */}
      <div 
        className="p-4 sm:p-4 mt-40 flex flex-col  md:flex-row gap-10 md:p-12 lg:p-16 xl:px-24">
      

      <div className="flex flex-col w-full md:w-4/5 xl:w-3/5 z-20 items-start ">
      <h2 className="font-sans text-enoOrange-200 leading-[1] sm:leading-[1] uppercase text-xl sm:text-2xl md:text-3xl">
{t('home.title4a')}
  </h2>
      <p className="font-serif text-white text-sm sm:text-base sm:leading-[1.25]">
      {t('home.p4a')}</p>
      <a
        onClick={handleScroll}
          href="#"
          className="text-white p-2 text-base px-6 border-2 mt-4  border-enoOrange-200 hover:border-white rounded-lg bg-enoOrange-200 
          -rotate-1 hover:rotate-1 uppercase leading-[1] transition-rotate duration-300 ease-in-out text-center w-full sm:w-auto"
        >
          {t('home.buy')}
        </a>
      </div>
      <h3 className="font-sans text-white text-left  left-0 -translate-y-1/4 w-full md:w-1/3 
        text-5xl leading-[0.9] xl:leading-[0.8]  md:text-8xl uppercase  z-[10]">
        <span className=" text-white opacity-25 pt-2 md:pt-0 px-2">
        {t('home.bigText2a')}</span>
        </h3>
      </div>

        {/* Form div */}
        <div ref={contactContainerRef}  id='contact' className="px-4 snap-start h-auto bg-enoDark md:px-6 lg:px-4 xl:px-10 py-5">


<ContactForm />

</div>



        </div>   
        
<Footer />
  </>
};

export default AlbumPage;



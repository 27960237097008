import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import ContactForm from '../components/Form';

function PenDrive() {
    const { t } = useTranslation("global");

    const contactContainerRef = useRef(null);

    const handleScroll = (e) => {
      e.preventDefault(); // Prevent default link behavior
      const contactContainer = contactContainerRef.current;
      if (contactContainer) {
        contactContainer.scrollIntoView({ behavior: 'smooth' });
      }
    };
  

    return <>
        <div className="h-auto bg-enoDark rounded-none md:rounded-lg relative snap-start flex flex-col
         md:flex-row items-center justify-center p-4 sm:p-4 md:p-16 lg:p-24 xl:p-32 gap-2">
            <div className="w-full md:w-3/5 ">
                <img 
                    src='/img/pendrive.webp'  // Assicurati di avere un'immagine della pen drive o dell'album
                    alt='Pen Drive 24/96 Massimo Chiarella Quartet' 
                    className='w-full mt-10  hover:rotate-3 uppercase transition-rotate duration-300 ease-in-out'
                />
            </div>
            <div className="flex flex-col justify-center items-start gap-4 w-full md:w-3/5">
                <h1 className="font-sans text-white text-xl sm:text-2xl md:text-4xl mb-2">
                    {t('penDrive.title')}
                </h1>
                <p className="font-serif text-white text-sm sm:text-base sm:leading-[1.25]">
                    {t('penDrive.description')}
                </p>
                <div className="bg-white/10 p-4 rounded-lg">
                    <h2 className="font-sans text-white text-lg mb-2">
                        {t('penDrive.whyChoose')}
                    </h2>
                    <p className="font-serif text-white text-sm">
                        {t('penDrive.whyChooseDescription')}
                    </p>
                </div>
                <div className="mt-4">
                    <h2 className="font-sans text-white text-lg mb-2">
                        {t('penDrive.howToOrder')}
                    </h2>
                    <p className="font-serif text-white text-sm">
                        {t('penDrive.howToOrderDescription')}
                    </p>
                </div>
                <a
                    href={t('penDrive.contactUrl')}
                    target="_blank"
                    onClick={handleScroll}
                    rel="noopener noreferrer"
                    className="mt-4 w-full md:w-52 bg-enoOrange-200 hover:bg-enoOrange-300 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                    {t('penDrive.contactButton')}
                </a>
            </div>
        </div>
            {/* Form div */}
    <div ref={contactContainerRef}  id='contact' className="px-4 snap-start h-auto bg-enoDark md:px-12 lg:px-1 xl:px-24 py-5">


<ContactForm />

</div>
        <Footer />
    </>
}

export default PenDrive;
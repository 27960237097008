import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeOff } from '@fortawesome/free-solid-svg-icons';

const ImageGallery = () => {
  const images = useMemo(() => [
    '/photos/2.webp', '/photos/5.webp', '/photos/8.webp', '/photos/12.webp',
    '/photos/9.webp', '/photos/6.webp', '/photos/1.webp', '/photos/3.webp',
    '/photos/11.webp', '/photos/10.webp', '/photos/4.webp'
  ], []);

  const [isMuted, setIsMuted] = useState(true);
  const [progressionIndex, setProgressionIndex] = useState(0);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const happyJazzProgression = useMemo(() => [
    'c4.mp3', 'e4.mp3', 'g4.mp3', 'c5.mp3',
    'f4.mp3', 'a4.mp3', 'c5.mp3', 'f5.mp3',
    'g4.mp3', 'b4.mp3', 'd5.mp3', 'g5.mp3',
    'a4.mp3', 'c5.mp3', 'e5.mp3', 'a5.mp3',
  ], []);

  const playJazzProgression = useCallback(() => {
    if (isMuted) return;

    const audioFile = `/audio/${happyJazzProgression[progressionIndex]}`;
    const audio = new Audio(audioFile);
    audio.play();

    setProgressionIndex((prevIndex) => (prevIndex + 1) % happyJazzProgression.length);
  }, [isMuted, progressionIndex, happyJazzProgression]);

  const toggleMute = useCallback(() => {
    setIsMuted((prevMuted) => !prevMuted);
  }, []);

  const shuffleImages = useCallback(() => {
    const shuffled = [...images].sort(() => Math.random() - 0.5);
    setDisplayedImages(shuffled);
  }, [images]);

  useEffect(() => {
    shuffleImages();
    setIsLoading(false);
  }, [shuffleImages]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setHoveredImageIndex(Math.floor(Math.random() * displayedImages.length));
      playJazzProgression();
    }, 600);

    return () => clearInterval(intervalId);
  }, [displayedImages.length, playJazzProgression]);

  const imageCount = window.innerWidth < 1120 ? 4 : 9;


  return (
    <div>
      <div className={`grid gap-2 ${window.innerWidth < 1120 ? 'grid-cols-2' : 'grid-cols-4 lg:grid-cols-3'}`}>
        {displayedImages.slice(0, imageCount).map((image, index) => (
          <div
            key={index}
            className="grid gap-2"
            onMouseEnter={() => {
              setHoveredImageIndex(index);
              playJazzProgression();
            }}
            onClick={playJazzProgression}
          >
            <div
              className={`w-full h-28 sm:h-40 bg-cover hover:scale-110 hover:shadow-3xl transition-transform duration-300 ease-in-out
               hover:shadow-white bg-center rounded-lg hover:rotate-2 ${hoveredImageIndex === index ? 'scale-95 -rotate-2' : ''}`}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          </div>
        ))}
      </div>
      <div className='flex flex-row justify-between mt-2'>
        <button onClick={toggleMute} className='text-xs right-14 top-10 text-enoOrange-200'>
          <FontAwesomeIcon
            icon={isMuted ? faVolumeMute : faVolumeOff}
            className="ml-4 text-xs font-serif"
          />
          {isMuted ? ' Unmute' : ' Mute'}
        </button>
      </div>
    </div>
  );
};

export default ImageGallery;

export const preloadImages = (images) => {
  const loadImage = (src) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = reject;
      img.src = src;
    });

  return Promise.all(images.map(loadImage));
};
// i18n.js
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import global_en from './translations/en/global.json';
import global_it from './translations/it/global.json';

i18next
    .use(initReactI18next)  
    .init({
        debug: false,
        interpolation: { escapeValue: true },
        lng: "en",
        resources: {
            en: { global: global_en },
            it: { global: global_it }
        }
    });

 export default i18next; 
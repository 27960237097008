import React from 'react';
import { useTranslation } from 'react-i18next';

function Privacy() {
    const { t } = useTranslation("global");

    return <div class="rounded-none md:rounded-lg snap-center bg-enoDark text-white font-serif items-start justify-center lg:items-center p-4 sm:p-2 md:p-16 lg:p-24 xl:p-32 gap-8">
    <h2 className='mb-5 text-4xl'>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</h2>
    <p>ANTONIO SARCINELLI POSTIGLIONE Legale rappresentante della società L'ENOTORRE S.A.S. DI ANTONIO POSTIGLIONE & C. (P.IVA 00632760930) con sede in VIA DI MEZZO 2, 33097 Spilimbergo PN ITALIA tel. 0427/2998 email: info@enotorrerecords.com, PEC: enotecalatorre1@pec.it, nella qualità di Titolare del trattamento (di seguito, anche il "Titolare") dei Suoi dati personali (di seguito, cumulativamente, anche "Dati"), di cui Lei è l'"Interessato", a norma del Regolamento UE 2016/679 (in prosieguo, il "Regolamento"), fornisce le seguenti informazioni affinché Lei possa prestare un consenso specifico, inequivocabile, informato, libero e consapevole al Trattamento dei Dati che potrà avvenire anche con strumenti informatici e/o telematici.</p>

     <h4 className='mt-5 text-2xl'>1) Fonte dei Dati</h4>
    <p>I dati necessari per lo svolgimento del rapporto contrattuale tra le Parti, nonché i dati altrimenti acquisiti nell'ambito di tale attività, sono raccolti dal Titolare direttamente dall'Interessato e/o per il tramite di soggetti terzi, nonché ottenuti consultando alcune banche dati esterne pubbliche e private.</p>

     <h4 className='mt-5 text-2xl'>2) Disponibilità dell'Informativa</h4>
    <p>Il Titolare fornisce la possibilità di consultare questa informativa:</p>

    <p>a) ogni volta che raccoglie i dati personali dei Clienti;</p>
    <p>b) ogni volta che raccoglie i dati dei Fornitori;</p>
    <p>c) ogni volta ne viene fatta espressa richiesta al Titolare.</p>

     <h4 className='mt-5 text-2xl'>3) Finalità del trattamento dei Dati</h4>
    <p>Il Titolare, per le proprie finalità istituzionali, connesse o strumentali all'attività, tratta i Suoi dati per i seguenti scopi:</p>

    <p>a) finalità connesse agli obblighi previsti da leggi italiane, da regolamenti e dalla normativa comunitaria nonché da disposizioni impartite da Autorità a ciò legittimate dalla legge o da organi di vigilanza e/o controllo;</p>

    <p>b) finalità contrattuali, connesse e strumentali alla instaurazione ed esecuzione degli incarichi affidati dalla clientela;</p>

    <p>c) finalità di profilazione e marketing.</p>

     <h4 className='mt-5 text-2xl'>4) Natura del conferimento dei Dati.</h4>

    <p>Lei ha il diritto di sapere che il conferimento dei dati può avere natura obbligatoria o facoltativa, nonché comportare conseguenze in caso di un eventuale rifiuto.</p>

    <p>A tale riguardo Le preciso che il conferimento del Suo consenso per le finalità di cui alle lettere a), b) del punto 3 ha natura obbligatoria in quanto previsto dalla legge o per dar seguito ad obbligazioni di tipo contrattuale (come indicato dall'art. 6, comma 1, lettera b e c) del Regolamento). Il diniego del Suo consenso per le finalità indicate alla lettera b) del punto 3 non permetterà, pertanto, al Titolare di eseguire l'incarico da Lei conferito o di giungere alla conclusione del contratto di cui Lei è parte.</p>

     <h4 className='mt-5 text-2xl'>5) Quali dati vengono trattati</h4>
    <p>I dati personali che il Titolare solitamente utilizza includono, a titolo esemplificato ma non esaustivo: nome, data e luogo di nascita, dati personali di contatto e qualifiche, documenti comprovanti l'identità personale, ecc.</p>

    <p>I dati personali trattati possono provenire da tutte le fonti necessarie per l'ottemperamento del rapporto contrattuale.</p>

     <h4 className='mt-5 text-2xl'>6) Dati particolari</h4>
    <p>È possibile che il Titolare venga in possesso di dati particolari in relazione a specifiche attività richieste dall'Interessato; in tale eventualità, l'Interessato ha facoltà di esprimere uno specifico consenso al trattamento dei propri dati sensibili ed il Titolare potrà utilizzarli esclusivamente nella misura indispensabile per poter eseguire i servizi e le operazioni richieste.</p>

     <h4 className='mt-5 text-2xl'>7) Conservazione dei Dati</h4>
    <p>I Dati saranno conservati per il tempo necessario a gestire il rapporto contrattuale ed adempiere ad obblighi di legge. I Dati sono trattati sempre nel pieno rispetto del principio di proporzionalità del trattamento (art. 5, comma 1, lett. c) del Regolamento), in base al quale tutti i Dati personali e le varie modalità del loro trattamento devono essere pertinenti e non eccedenti rispetto alle finalità perseguite, in modo da garantirne un'adeguata sicurezza e riservatezza, anche per impedire l'accesso o l'utilizzo non autorizzato dei dati personali e delle attrezzature impiegate per il trattamento, nonché un trattamento lecito e corretto.</p>

     <h4 className='mt-5 text-2xl'>8) Tempi di conservazione dei Dati</h4>
    <p>In virtù di quanto disposto dall'art. 13, comma 2, lett. a) del Regolamento, con riferimento alle lettere a), b) e c) del punto 3 che precede, i Dati sono conservati per il periodo necessario a gestire il contratto ed adempiere agli obblighi di legge e/o, comunque, sino al decorso del termine prescrizionale civile ordinario (10 anni).</p>

     <h4 className='mt-5 text-2xl'>9) Modalità del trattamento dei Dati</h4>
    <p>In relazione alle finalità indicate al punto 3, i Dati personali potranno essere trattati anche da un "Responsabile", ossia la persona fisica o la società, anche esterne al Titolare, cui potranno essere affidati specifici e definiti compiti di gestione e controllo del trattamento dei dati e/o da uno o più "Incaricato/i", che provvederanno all'elaborazione o utilizzazione materiale dei Dati sulla base delle istruzioni ricevute dal Titolare e/o dal Responsabile.</p>

    <p>Sempre in relazione alle finalità sopra indicate, i Dati potranno essere trattati attraverso strumenti manuali, informatici e/o altrimenti automatizzati secondo logiche strettamente connesse alle finalità del trattamento e, comunque, in modo da garantirne la sicurezza e riservatezza anche nel caso di trattamento attraverso strumenti di comunicazione a distanza.</p>

     <h4 className='mt-5 text-2xl'>10) Trasferimento dei Dati all'estero </h4>
    <p>I Dati potrebbero essere trasferiti verso Paesi dell'Unione Europea e verso Paesi terzi (ossia non aderenti all'Unione europea) esclusivamente nell'ambito delle finalità di cui alle lettere a), b) e c) del punto 3. In mancanza di una decisione di adeguatezza ai sensi dell'art. 45 del Regolamento o di garanzie adeguate ai sensi dell'art. 46 del Regolamento, il trasferimento dei dati personali avverrà conformemente a quanto previsto dall'art. 49 del Regolamento.</p>

     <h4 className='mt-5 text-2xl'>11) Comunicazione dei Dati</h4>
    <p>In ragione del rapporto instauratosi e al fine di agevolare e consentire l'esecuzione del contratto, i Dati potranno essere comunicati a: i) collaboratori esterni; ii) altri intermediari (anche società) individuati dal Titolare al fine di collaborare per l'espletamento del contratto affidato dall'Interessato; iii)Organi di Vigilanza/Controllo e altre Autorità, per finalità connesse agli obblighi previsti da legge e/o da regolamenti, nonché da disposizioni impartite dalle medesime Autorità.</p>

    <p>Tutti i soggetti, appartenenti alle categorie ai quali i Dati potranno essere comunicati, utilizzeranno i Dati in qualità di "titolari" ai sensi della legge o di specifico consenso, in piena autonomia, essendo estranei all'originario trattamento effettuato presso il Titolare, ovvero di "responsabili esterni". Per conoscere in qualsiasi momento i soggetti cui i Suoi dati verranno comunicati, è sufficiente che Lei richieda l'elenco aggiornato scrivendo al Titolare.</p>

    <p>Infine i Suoi Dati saranno conosciuti da designati responsabili e/o incaricati del trattamento, in relazione allo svolgimento delle mansioni e dei compiti a ciascuno attribuiti.</p>

    <p>I Dati trattati dal Titolare non saranno oggetto di diffusione.</p>

     <h4 className='mt-5 text-2xl'>12) Diritti dell'interessato </h4>
    <p>Nei limiti e alle condizioni previste dagli artt. 15 – 23 del Regolamento, il Titolare Le garantisce e Le riconosce l'esercizio dei seguenti diritti:</p>
    <ul>
        <li>• il diritto di accedere ai dati personali presenti in propri archivi cartacei e/o elettronici;</li>
        <li>• il diritto di chiederne la rettifica, l'aggiornamento e la cancellazione, se incompleti o erronei, nonché di opporsi al loro trattamento per motivi legittimi e specifici;</li>
        <li>• il diritto di ottenere la rettifica dei dati personali inesatti senza ingiustificato ritardo. Tenuto conto delle finalità del trattamento, l'interessato ha il diritto di ottenere l'integrazione dei dati personali incompleti, anche fornendo una dichiarazione integrativa;</li>
        <li>• il diritto di ottenere la cancellazione dei dati personali che lo riguardano senza ingiustificato ritardo se sussiste uno dei motivi di cui all'art. 17, comma 1 del Regolamento;</li>
        <li>• il diritto di ottenere la limitazione del trattamento quando ricorre una delle ipotesi di cui all'art. 18, comma 1 del Regolamento;</li>
        <li>• il diritto alla portabilità del dato nei limiti e nei modi previsti dall'art. 20 del Regolamento.</li>
     </ul>
    <p>Lei ha, inoltre, in qualsiasi momento, il diritto di revocare il consenso al trattamento dei suoi dati senza pregiudicare in alcun modo la liceità del trattamento basata sul consenso prestato prima della revoca, nonché di opporsi in qualsiasi momento al trattamento per finalità di marketing (diritto di opposizione).</p>

    <p>Lei ha il diritto di ricevere le informazioni relative all'azione intrapresa riguardo a una delle suddette richieste o agli effetti nascenti dall'esercizio di uno dei suddetti diritti, senza ingiustificato ritardo e, comunque, al più tardi entro un mese dal ricevimento della richiesta stessa, prorogabile, se necessario, di due mesi; ha, altresì, il diritto di proporre reclamo a un'autorità di controllo e di proporre ricorso giurisdizionale.</p>

    <p>Fatto salvo il caso in cui il trattamento dei Dati violi i principi generali dettati dal Regolamento, l'esercizio di tali diritti dovrà essere pertinente e motivato e non potrà implicare la revoca del consenso prestato o la richiesta di cancellazione dei Dati da Lei forniti per la conclusione e l'esecuzione del contratto di cui al punto 3) lettere a), b) e c), nella misura in cui e fintantoché i Dati siano necessari a tale finalità.</p>

    <p>I diritti in oggetto potranno essere esercitati, anche per il tramite di un incaricato, mediante richiesta rivolta al Titolare o al Responsabile del trattamento dei Dati ai seguenti indirizzi:</p>

    <p>ANTONIO SARCINELLI POSTIGLIONE Legale rappresentante della società L'ENOTORRE S.A.S. DI ANTONIO POSTIGLIONE & C.(P.IVA 00632760930) con sede in VIA DI MEZZO 2 33097 Spilimbergo PN ITALIA tel. 0427/2998 email: info@enotorrerecords.com, PEC: enotecalatorre1@pec.it
    Nell'esercizio dei diritti, Lei potrà conferire, per iscritto, delega o procura a persone fisiche, enti associazioni od organismi; potrà, altresì, farsi assistere da una persona di fiducia. Il Titolare, per garantire l'effettivo esercizio dei Suoi diritti, adotterà le misure idonee volte ad agevolare l'accesso ai Dati, a semplificare le modalità e a ridurre i tempi per dare relativo riscontro alla Sua richiesta.</p>

    <p>Infine si segnala che Lei potrà proporre reclamo all'autorità di controllo ossia al Garante per la Protezione dei Dati Personali attraverso i seguenti mezzi:</p>

    <p>a) raccomandata A/R indirizzata al Garante per la Protezione dei Dati Personali, Piazza Venezia n.11 – 00187 Roma;
    b) e-mail all'indirizzo: garante@gpdp.it, oppure protocollo@pec.gpdp.it;
    c) fax al numero: 06/69677.3785.
    Tutte le relative informazioni sui reclami proponibili dall'Interessato sono disponibili sul sito web del Garante all'indirizzo www.garanteprivacy.it.</p>
     <h4 className='mt-5 text-2xl'>Form Contatti</h4>
    <p>I Suoi dati personali, forniti compilando il form presente nell'area "contatti" di questo sito web (www.enotorrerecords.com) e quelli contenuti in messaggi di posta elettronica inviati direttamente a indirizzi presenti nella stessa area "contatti", saranno oggetto di trattamento improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti o di quelli relativi ad eventuali Suoi dipendenti o collaboratori.</p>

    <p>L'azienda si impegna inoltre a trattare i Suoi dati nel rispetto del principio di "minimizzazione", ovvero acquisendo e trattando i dati limitatamente a quanto necessario rispetto alle seguenti finalità:</p>

    <p>gestione del servizio richiesto e/o di informazioni. Per questa finalità non saranno richiesti e/o trattati dati personali di tipo sensibile.</p>

    <p>I Suoi dati potranno essere utilizzati per le finalità di seguito riportate solo con il Suo specifico consenso che potrà essere comunque revocato in qualunque momento con una comunicazione ai recapiti sotto riportati; il conferimento dei dati e del consenso per queste finalità è facoltativo e un eventuale rifiuto non pregiudicherà la Sua possibilità di ricevere le informazioni che ci ha richiesto. Le finalità sono:</p>

    <ul>
        <li>invio di circolari informative, tecniche e/o commerciali sia in via telematica che cartacea</li>
        <li>gestione attività promozionali e/o di marketing in via cartacea e/o telematica</li>
        <li>gestione invio di listini, cataloghi, istruzioni tecniche, campionature ecc...</li>
        <li>compimento di ricerche di mercato</li>
    </ul>

    <p>Il trattamento sarà effettuato sia con strumenti manuali e/o informatici e telematici con logiche di organizzazione ed elaborazione strettamente correlate alle finalità stesse e comunque in modo da garantire la sicurezza, l'integrità e la riservatezza dei dati stessi nel rispetto delle misure organizzative, fisiche e logiche previste dalle disposizioni vigenti.</p>

    <p>I dati personali vengono conservati per tutta la durata del rapporto di collaborazione e, nel caso di revoca e/o altro tipo di cessazione del rapporto, nei termini prescrizionali indicati nell'art. 2220 del Codice Civile.</p>

    <p>Lei potrà, in qualsiasi momento, esercitare i diritti:</p>

    <ul>
        <li>di accesso ai dati personali;</li>
        <li>di ottenere la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano;</li>
        <li>di opporsi al trattamento;</li>
        <li>alla portabilità dei dati;</li>
        <li>di revocare il consenso, ove previsto: la revoca del consenso non pregiudica la liceità del trattamento basata sul consenso conferito prima della revoca;</li>
        <li>di proporre reclamo all'autorità di controllo (Garante Privacy).</li>
    </ul>

    <p>L'esercizio dei suoi diritti potrà avvenire attraverso l'invio di una richiesta mediante email all'indirizzo info@enotorrerecords.com</p>

    <p>Il Titolare del trattamento dati è ANTONIO SARCINELLI POSTIGLIONE Legale rappresentante della società L'ENOTORRE S.A.S. DI ANTONIO POSTIGLIONE & C.(P.IVA 00632760930) con sede in VIA DI MEZZO 2 33097 Spilimbergo PN ITALIA. Il Responsabile del trattamento è ANTONIO SARCINELLI POSTIGLIONE.</p>
</div>
}

export default Privacy;

